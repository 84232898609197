import * as React from "react"
import { BrowserRouter, Route } from "react-router-dom";

// markup
const IndexPage = () => {
  return (
    <main>
      <h1>Testing</h1>
    </main>
  )
}

export default IndexPage
